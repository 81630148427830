( function( $ ) {
  $( "input, textarea" ).each( function() {
    if ( $( this ).is( ':checkbox' ) ) {} else {
      if ( $( this ).val() ) {
        $( this ).addClass( 'used' );
      }
    }
  } );

  $( 'input' ).change( function() {
    if ( $( this ).is( ':checkbox' ) ) {} else {
      if ( $( this ).val() )
        $( this ).addClass( 'used' );
      else
        $( this ).removeClass( 'used' );
    }
  } );

  $( 'textarea' ).change( function() {
    if ( $( this ).val() )
      $( this ).addClass( 'used' );
    else
      $( this ).removeClass( 'used' );
  } );

  //Handle the contact form
  var contactform = $( '#main-contact-form' );

  var target = Routing.generate( 'site_frontend_ajax_call' );
  contactform.submit( function( e ) {
    $( '.js-submit' ).prop( 'disabled', true );

    e.preventDefault();
    var form_status = $( '<div class="form_status"></div>' );
    $.ajax( {
      type: contactform.attr( 'method' ),
      url: target,
      beforeSend: function() {
        contactform.prepend( form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> Email is sending...</p>' ).fadeIn() );
      },
      data: {
        entity: 'contact',
        form: contactform.serializeArray()
      },
      success: function( data ) {
        if ( data.status == 'ok' ) {
          form_status.html( '<p class="text-success">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          contactform.trigger( 'reset' );
          $( '.js-submit' ).prop( 'disabled', false );

        } else {
          form_status.html( '<p class="text-error">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
        }
      }
    } );
  } );



  var customForm = $( '#custom-form' );
  var target = Routing.generate( 'site_frontend_ajax_call' );
  customForm.submit( function( e ) {
    e.preventDefault();
    $( '.js-submit' ).prop( 'disabled', true );

    var form_status = $( '<div class="form_status"></div>' );

    $.ajax( {
      type: 'POST',
      url: target,
      beforeSend: function() {
        console.log( 'before send' );
        customForm.append( form_status.html( '<p><i class="fa fa-spinner fa-spin"></i> Email is sending...</p>' ).fadeIn() );
      },
      data: {
        entity: 'custom',
        form: customForm.serializeArray()
      },
      success: function( data ) {
        if ( data.status == 'ok' ) {
          form_status.html( '<p class="text-success">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
          customForm.trigger( 'reset' );
          $( '.js-submit' ).prop( 'disabled', false );

        } else {
          form_status.html( '<p class="text-error">' + data.message + '</p>' ).delay( 3000 ).fadeOut();
        }
      }
    } );
  } );




  //Handle the guestbook form
  var reviewform = $( '#review__form' );
  var form_status = $( '.js-status' );
  var reviewtarget = Routing.generate( 'site_frontend_ajaxTestimonial_call' );
  reviewform.submit( function( e ) {
    e.preventDefault();

    $.ajax( {
      type: 'POST',
      url: reviewtarget,
      beforeSend: function() {
        form_status.addClass( 'isLoading' );
      },
      data: {
        entity: 'review',
        form: reviewform.serializeArray()
      },
      success: function( data ) {
        if ( data.status == 'ok' ) {
          form_status.removeClass( 'isLoading' );
          form_status.addClass( 'loadingSuccess' );
          $( '.js-review-succes' ).fadeIn();
          setTimeout( function() {
            reviewform[ 0 ].reset();
            closeModal();
          }, 4000 );

        } else {
          form_status.addClass( 'loadingError' );
          $( '.js-review-error' ).fadeIn();
        }
      }
    } );
  } );

  function closeModal() {
    $( '.rating__userscore' ).raty( 'score', 0 );
    $( "input[name='score']" ).val( null );
    $( '#rating img' ).removeClass( 'selected' );
    $( '.rating__caption' ).text( 'click to rate' );
    $( '.js-review' ).css( 'visibility', 'hidden' );
    $( '.js-review' ).css( 'opacity', '0' );
    form_status.removeClass( 'loadingSuccess' );
    form_status.removeClass( 'loadingError' );
    $( '.js-review-error' ).fadeOut();
    $( '.js-review-succes' ).fadeOut();
  }

} )( jQuery );
