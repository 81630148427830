$('.js-slider').slick({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 4000,
  dots: true,
  appendDots: $('.slider__dots')
});

$('.js-slider-page').slick({
  arrows: true,
  nextArrow: '<div class="subpage__arrow subpage__arrow--next"></div>',
  prevArrow: '<div class="subpage__arrow subpage__arrow--prev"></div>'
});

$('.js-reviews').slick({
  arrows: true,
  nextArrow: '<div class="subpage__arrow review__arrow--next"></div>',
  prevArrow: '<div class="subpage__arrow review__arrow--prev"></div>',
  slidesToShow: 2,
  slidesToScroll: 1,
});


$('.js-highlight-carousel').slick({
  arrows: true,
  autoplay: false,
  nextArrow: '<div class="subpage__arrow highlight-arrow highlight-arrow--next"></div>',
  prevArrow: '<div class="subpage__arrow highlight-arrow highlight-arrow--prev"></div>',
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.js-slider-header').slick({
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [{
    breakpoint: 768,
    settings: "unslick"
  }]
});
var rs = 0;

$('.js-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  var direction = "";
  if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
    // its going from the first slide to the last slide (backwards)
    direction = 'prev';
    rs += 45;
    console.log(direction);
  } else if (nextSlide > currentSlide || (currentSlide === (slick.$slides.length - 1) && nextSlide === 0)) {
    // its either going normally forwards or going from the last slide to the first
    direction = 'next';
    rs -= 45;
    console.log(direction);
  } else {
    direction = 'prev';
    rs += 45;
    console.log(direction);
  }


  $('.js-bruges').css({
    'transform': 'rotate(' + rs + 'deg)'
  });

if (currentSlide === 0 ){
  $('.js-slide-img').attr("src","/bundles/sitefrontend/img/varkentje.png");
  $('.js-slide-img').addClass('piggy');
  $('.js-moneystamp').addClass('showmoney');


} else {
  $('.js-slide-img').attr("src","/bundles/sitefrontend/img/IMABUS.png");
  $('.js-slide-img').removeClass('piggy');
  $('.js-moneystamp').removeClass('showmoney');


}

});